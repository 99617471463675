import React from "react";
import { Link } from "gatsby";
var slugify = require("slugify");

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
}

const RelatedQA = ({ RelatedService, data }) => {
	// const datas = useStaticQuery(graphql`
	// 	query MyQuery($RelatedService: String) {
	// 		allFaqsCsv(
	// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
	// 		) {
	// 			distinct(field: { Search: SELECT })
	// 		}
	// 	}
	// `);
	return (
		<>
			{data.group && data.group.length > 0 ? (
				<section className="mt-20 mx-auto  max-w-screen-xl items-center mb-20   justify-between px-4">
					<h3 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
						Other Areas we Cover for {titleCase(RelatedService)}
					</h3>

					<div className="grid grid-cols-1 md:grid-cols-4 gap-6 divide divide-y max-h-96 p-6 overflow-auto">
						{data?.group?.map((item, index) => {
							var itemed = item.edges[0].node;
							return (
								// <div>{item.edges[0].node.name}</div>
								<Link
									to={`/${slugify(RelatedService, { lower: true })}/${slugify(
										String(itemed.name),
										{ lower: true }
									)}`}
									className={
										"col-span-1   md:col-span-2 shadow  rounded p-8 my-auto outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300"
									}
								>
									<p className=" text-gray-500 text-center  tracking-wide ">
										{titleCase(itemed.name)}
									</p>
								</Link>
							);
						})}
					</div>
				</section>
			) : null}
		</>
	);
};

// export const query = graphql`
// 	query MyQuery($RelatedService: String) {
// 		allFaqsCsv(
// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
// 		) {
// 			edges {
// 				node {
// 					Related_Service
// 				}
// 			}
// 		}
// 	}
// `;

export default RelatedQA;
