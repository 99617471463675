import * as React from "react";
import { graphql } from "gatsby";
import Header from "../components/includes/defaults/header";
import Carousel from "../components/includes/services/carousel";
import Cta from "../components/CTA/CTAMain";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Footer from "../components/includes/defaults/footer";
import RelatedQA from "../components/includes/relatedQA";
import QuickInfo from "../components/includes/quickInfo";
import Seo from "../components/seo";
import RelatedCities from "../components/includes/relatedCities";
import Layout from "../components/Defaults/Layout";

const OurGarantee = [
	{
		content: "Free site visit and survey",
	},
	{
		content: "No hidden costs",
	},
	{
		content: "British standards compliant ",
	},
	{
		content: "Fully Insured",
	},
	{
		content: "Clean and efficient",
	},
	{
		content: "Professional workmanship",
	},
];

const LayoutOne = ({ node }) => {
	return (
		<>
			<div className="md:p-0 p-4">
				<h2 className="text-2xl text-blue-500 text-center border-b-2 mb-4 pb-2">
					{node?.node?.title}
				</h2>
				<p>{node?.node?.Output}</p>
				<div className="mt-10 px-10 mb-10  bg-blue-900 outline outline-offset-[6px] border-2 border-slate-800/50  hover:outline-offset-0 outline-slate-500/40 hover:outline-slate-500/70 duration-150  overflow-hidden rounded-lg py-6 ">
					<h3 className=" pb-2 text-2xl text-white text-center  border-b-2">
						Our Guarantee
					</h3>
					<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-2">
						{OurGarantee.map((item, index) => {
							return (
								<div className="col-span-1 text-white md:col-span-1">
									<p>{item.content}</p>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

const LayoutWithImageRight = ({ node, image }) => {
	return (
		<div className="md:p-0 p-4">
			<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 border-b-2 pb-6">
				<div className="col-span-1 text-black md:col-span-1">
					<h3 className="text-2xl text-blue-500  mb-4 pb-2">
						{node?.node?.title}
					</h3>
					<p>{node?.node?.Output}</p>
				</div>
				<div className="col-span-1 text-black md:col-span-1">
					<GatsbyImage
						image={getImage(image.childImageSharp)}
						className="mx-auto rounded-2xl border-2 border-slate-800"
						placeholder="blurred"
						alt={node?.node?.title}
						style={{ height: "100%", width: "100%" }}
					/>
				</div>
			</div>
		</div>
	);
};

const LayoutWithImageLeft = ({ node, image }) => {
	return (
		<div className="md:p-0 p-4">
			<div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4 pb-6">
				<div className="col-span-1 text-black md:col-span-1">
					<GatsbyImage
						image={getImage(image.childImageSharp)}
						className="mx-auto  rounded-2xl border-2 border-slate-800"
						placeholder="blurred"
						alt={node?.node?.title}
						style={{ height: "100%", width: "100%" }}
					/>
				</div>
				<div className="col-span-1 text-black md:col-span-1">
					<h3 className="text-2xl text-blue-500  mb-4 pb-2">
						{node?.node?.title}
					</h3>
					<p>{node?.node?.Output}</p>
				</div>
			</div>
		</div>
	);
};

const CTAFour = ({ data }) => {
	return (
		<>
			{data ? (
				<section className="mt-10 md:p-0 p-4  mx-auto  max-w-screen-xl items-center mb-6 justify-between ">
					<div className="grid grid-cols-1 md:grid-cols-4 gap-6 divide divide-y">
						{data?.map((service, index) => {
							return (
								<div className="col-span-1   md:col-span-4 shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300">
									<h3 className="  text-xl text-black mb-2 border-b-2 mb-2  ">
										{service?.node?.title}
									</h3>
									<p className=" text-gray-500  tracking-wide ">
										{service?.node?.Output}
									</p>
								</div>
							);
						})}
					</div>
				</section>
			) : null}
		</>
	);
};

const SingleOne = ({ data }) => {
	return (
		<>
			{data ? (
				<section className="mb-6 md:p-0 p-4  mx-auto  max-w-screen-xl items-center mb- justify-between ">
					<div className="grid grid-cols-1 md:grid-cols-1 gap-6 divide divide-y">
						<div className="col-span-1   md:col-span-1 rounded p-2">
							<h4 className="text-3xl mb-10  text-blue-500 text-center py-6  border-b-2 ">
								{data?.node?.title}
							</h4>
							<p className=" text-gray-500  tracking-wide ">
								{data?.node?.Output}
							</p>
						</div>
					</div>
				</section>
			) : null}
		</>
	);
};

const servicePage = ({ pageContext, data }) => {
	const { group, related_service } = pageContext;
	const { edges } = group;
	// const {rel}
	const { images } = data;
	const { allImages } = images;
	const nextFour = edges.slice(2, 6);
	const edgesLength = edges.length;
	const last = edges.slice(6, edgesLength);

	return (
		<>
			<Layout>
				<Carousel allImages={allImages[0]} related_service={related_service} />
				<Cta background={true} />
				<section className="mt-10 mx-auto  max-w-screen-xl items-center mb-20 justify-between  ">
					{/* <h4 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
						URGENTLY REQUIRE OUR {String(related_service).toUpperCase()}? CALL
						US NOW ON
						<span className="text-black"> 01452 452049</span>
					</h4> */}
					<div className="grid grid-cols-1 md:grid-cols-12 gap-8">
						<div className="col-span-1 md:col-span-9">
							{edges.map((node, index) => {
								if (index === 0) {
									return (
										<>
											<LayoutOne node={node} />
										</>
									);
								} else if (index === 1) {
									return (
										<>
											<LayoutWithImageRight node={node} image={allImages[0]} />
										</>
									);
								} else if (index === 2) {
									return (
										<>
											<LayoutWithImageLeft node={node} image={allImages[1]} />
										</>
									);
								} else {
									return null;
								}
							})}
						</div>
						<div className="col-span-1 md:col-span-3 p-4 md:p-0 ">
							<QuickInfo />
						</div>
					</div>
					<Cta
						Heading={"Need Help?"}
						subHeading={"Call Us Now On - XXXPHONE"}
						callName={true}
					/>
					<CTAFour data={nextFour} />
					<RelatedQA RelatedService={related_service} data={data?.relatedQA} />

					<Cta
						Heading={"Need Help?"}
						subHeading={"Call Us Now On - XXXPHONE"}
						callName={true}
					/>

					{last && last.length > 0
						? last.map((node, index) => {
								return (
									<>
										<SingleOne data={node} />
									</>
								);
						  })
						: null}

					<RelatedCities
						RelatedService={related_service}
						data={data?.serviceCities}
					/>
				</section>
			</Layout>
		</>
	);
};

export const Head = ({
	pageContext: { related_service },
	data: {
		site: { siteMetadata },
	},
}) => {
	const description = `At ${siteMetadata.title} we provide ${related_service} in ${siteMetadata.mainArea} and surrounding Areas. We are available 24/7 for any emergency call us on ${siteMetadata.phoneNum}`;
	return (
		<Seo
			title={`${related_service} in ${siteMetadata.mainArea}`}
			description={description}
		/>
	);
};

export const query = graphql`
	query allServicePages($related_service: String) {
		images: allFile(
			sort: { modifiedTime: DESC }
			filter: {
				extension: { regex: "/(jpg)|(jpeg)|(png)/" }
				relativeDirectory: { eq: $related_service }
			}
		) {
			allImages: nodes {
				dir
				absolutePath
				base
				extension
				relativePath
				relativeDirectory
				root
				sourceInstanceName
				ext
				publicURL
				prettySize
				name
				childImageSharp {
					gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
				}
			}
		}
		site {
			siteMetadata {
				title
				mainArea
				mainService
				phoneNum
			}
		}
		relatedQA: allFaqsCsv(
			filter: { Related_Service: { eq: $related_service } }
		) {
			distinct(field: { Search: SELECT })
		}
		serviceCities: allBranchesCsv(
			filter: {
				type: { eq: "City" }
				related_service: { eq: $related_service }
			}
		) {
			group(field: { Article_Title: SELECT }, limit: 1) {
				edges {
					node {
						id
						Article_Title
						related_service
						name
					}
				}
			}
		}
	}
`;

export default servicePage;
